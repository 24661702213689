import { HTTP } from '@/store/modules/auth'

const login = async (authCode) => {
  return await HTTP.get('/login/google/callback', {
    params: {
      code: authCode,
      state: '',
      origin: window.origin
    }
  })
}

const logout = async () => {
  return await HTTP.get('/logout')
}

const getProfile = async () => {
  return await HTTP.get('/profile')
}

const setLocale = async (value) => {
  return await HTTP.post('/profile/setting', {field: 'locale', value})
}

const linkTelegramAccount = async () => {
  return await HTTP.post('/profile/linkTelegramAccount')
}

const getOptimizationCatalog = async () => {
  return await HTTP.get('/optimization/catalog')
}

const getOptimizationCampaigns = async (pageSize, offset, filter, onModeration) => {
  let body

  if (filter) body = {pageSize, offset, filter}
  else body = {pageSize, offset}

  if (onModeration)
    body.onModeration = onModeration

  return await HTTP.post('/optimization/campaigns', body)
}

const getCampaignInfoFromCab = async (body) => {
  return await HTTP.get('/optimization/campaign/info', {
    params: body
  })
}

const getOptimizationStatistic = async (body) => {
  return await HTTP.post('/optimization/statistic', body)
}

const checkCampaign = async (body) => {
  return await HTTP.post('/optimization/check/campaign', body)
}

const saveNewCampaign = async (body) => {
  return await HTTP.post('/optimization/campaign/new', body)
}

const cancelModerationCampaign = async (body) => {
  return await HTTP.post('/optimization/campaign/cancelModeration', body)
}

const moderationCampaign = async (body) => {
  return await HTTP.post('/optimization/campaign/moderate', body)
}

const optimizeCampaign = async (body) => {
  return await HTTP.post('/optimization/campaign/optimize', body)
}

const updateValue = async (body) => {
  return await HTTP.post('/optimization/campaign/update/value', body)
}

const getHistoryOfFieldForCampaign = async (body) => {
  return await HTTP.get('/optimization/history/campaign/field', {
    params: body
  })
}

const getErrorInUrl = async (limit, page, creator, whoAdded) => {
  return await HTTP.get('/optimization/errors/inUrl', {
    params: {
      whoAdded,
      creator,
      limit,
      page
    }
  })
}

const optimizationUpdateCampaignLimit = async (data) => {
  return await HTTP.post('/optimization/update/campaign/limit', data)
}

const optimizationUpdateCampaignStatus = async (data) => {
  return await HTTP.post('/optimization/update/campaign/status', data)
}

const dspStatistic = async (limit, page) => {
  return await HTTP.get('/dsp/statistic', {
    params: {
      limit,
      page
    }
  })
}

const getCatalogDsp = async () => {
  return await HTTP.get('/dsp/catalog/dsp')
}

const getCatalogForTeasers = async (networkName) => {
  return await HTTP.get('/dsp/catalog/teaser', {params: {networkName}})
}



const dspTeasers = async (params) => {
  return await HTTP.post('/dsp/teasers', params)
}

const dspTeasersSetFolder = async (params) => {
  return await HTTP.post('/dsp/teaser/setFolder', params)
}

const dspTeaserNew = async (body) => {
  return await HTTP.post('/dsp/teaser/new', body)
}

const dspTeaserDeleteFromModeration = async (id, networkName) => {
  return await HTTP.delete('/dsp/teaser', {params: {id, networkName}})
}

const dspTeaserSendModerateAgain = async (body) => {
  return await HTTP.post('/dsp/teaser/update', body)
}

const dspTeaserCancelModerate = async (body) => {
  return await HTTP.post('/dsp/teaser/cancelModeration', body)
}

const dspTeaserModerate = async (body) => {
  return await HTTP.post('/dsp/teaser/moderate', body)
}

const dspTeaserTasks = async (params) => {
  return await HTTP.get('/dsp/teaser/tasks', {params})
}

const dspTeaserExternalUpdates = async (params) => {
  return await HTTP.get('/dsp/teaser/externalUpdates', {params})
}

const dspTeaserExternalUpdatesListTeasersForTasks = async (params) => {
  return await HTTP.get('/dsp/teaser/externalUpdates/listTeasersForTasks', {params})
}

const dspTeaserCreateTask = async (body) => {
  return await HTTP.post('/dsp/teaser/task/create', body)
}

const dspTeaserAbTasks = async (params) => {
  return await HTTP.get('/dsp/teaser/ab-tasks', {params})
}


const dspAuctionStat = async (params) => {
  return await HTTP.get('/dsp/teaser/auction-stat', {params})
}

const dspGetClimitAchieved = async (params) => {
  return await HTTP.get('/dsp/teaser/climit-achieved', {params})
}

const dspTeaserCreateAbTask = async (body) => {
  return await HTTP.post('/dsp/teaser/ab-task/create', body)
}

const dspTeaserMassChanges = async (body) => {
  return await HTTP.post('/dsp/teaser/task/mass/create', body)
}

const dspTeaserUpdateFromCallback = async (body) => {
  return await HTTP.post('/dsp/teaser/task/fromCallback/create', body)
}

const dspUploadImg = async (file, tags, crop, type) => {
  const formData = new FormData()
  formData.append('image', file)
  formData.append('tags', tags)
  formData.append('type', type)
  if (crop) {
    formData.append('crop', crop)
  }

  return await HTTP.post('/dsp/teaser/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


const dspUploadFileForParse = async (file, type) => {
  const formData = new FormData()
  formData.append('fileForParse', file)
  formData.append('type', type)

  return await HTTP.post('/dsp/parser/file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const dspTeaserDeleteTask = async (type, operationId, networkName) => {
  return await HTTP.delete('/dsp/teaser/task/delete', {params: {type, operationId, networkName}})
}

const dspTeaserImages = async (params) => {
  return await HTTP.get('/dsp/teaser/images', {params})
}

const dspTeaserImagesTags = async (type) => {
  return await HTTP.get('/dsp/teaser/images/tags', {params: {type}})
}

const dspTeaserImagesTagsWithRating = async (type) => {
  return await HTTP.get('/dsp/teaser/images/tags', {params: {type, rating: true}})
}

const dspDownloadImg = async (body) => {
  return await HTTP.post('/dsp/teaser/image/download', body)
}

const dspUpdateImgTags = async (body) => {
  return await HTTP.post('/dsp/teaser/image/tag', body)
}

const dspTargetUa = async (params) => {
  return await HTTP.get('/dsp/teaser/target/ua', {params})
}

const dspTargetUaInsert = async (body) => {
  return await HTTP.post('/dsp/teaser/target/ua', body)
}

const dspTargetUaDelete = async (body) => {
  return await HTTP.post('/dsp/teaser/target/ua/delete', body)
}

const dspTargetUaUpdate = async (body) => {
  return await HTTP.post('/dsp/teaser/target/ua/update', body)
}

const dspScheduleUpdate = async (body) => {
  return await HTTP.post('/dsp/teaser/schedule', body)
}

const dspUpdateLang = async (body) => {
  return await HTTP.post('/dsp/teaser/update/lang', body)
}

const dspMediaDescription = async (body) => {
  return await HTTP.post('/dsp/teaser/update/media_description', body)
}

const dspLandingKeywords = async (body) => {
  return await HTTP.post('/dsp/teaser/update/landing_keywords', body)
}

const dspCatalogTargetUa = async () => {
  return await HTTP.get('/dsp/teaser/catalog/target/ua')
}

const dspTeaserValidate = async (body) => {
  return await HTTP.post('/dsp/teaser/validate', body)
}

const getSpending = async () => {
  return await HTTP.get('/statistic/spending/networks')
}

const getSpendingCatalog = async () => {
  return await HTTP.get('/statistic/spending/campaigns/catalog')
}

const getCampaignsDayStatistic = async (body) => {
  return await HTTP.post('/statistic/spending/campaigns', body)
}



const getCatalogForEarnings = async () => {
  return await HTTP.get('/statistic/earning/catalog')
}

const getEarningStatistic = async (body) => {
  return await HTTP.post('/statistic/earning/networks', body)
}

const runFetchCampaignsDayStatistic = async (startDate, endDate) => {
  return await HTTP.post('/statistic/spending/fetch', {startDate, endDate})
}

const uploadNetworkStatisticData = async (formData) => {
  return await HTTP.post('/statistic/earning/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
}

const flowList = async (body) => {
  return await HTTP.post('/flow/list', body)
}

const flowCatalog = async () => {
  return await HTTP.get('/flow/catalog')
}

const flowCreate = async (body) => {
  return await HTTP.post('/flow/create', body)
}

const tasksList = async (body) => {
  return await HTTP.post('/task/list', body)
}

const affNetworkGetAccountCatalog = async (body) => {
  return await HTTP.post('/affNetwork/catalog/accounts', body)
}
const affNetworkGetCatalogNetwork = async (body) => {
  return await HTTP.post('/affNetwork/catalog/network', body)
}

// const affNetworkGetCatalogNetworks = async (body) => {
//   return await HTTP.post('/affNetwork/catalog/networks', body)
// }
const affNetworkGetOffers = async (body) => {
  return await HTTP.post('/affNetwork/offers', body)
}
const affNetworkGetCampaigns = async (body) => {
  return await HTTP.post('/affNetwork/campaigns', body)
}

const affNetworkUpdateName = async (body) => {
  return await HTTP.post('/affNetwork/update/name', body)
}

const affNetworkCreatePixel = async (body) => {
  return await HTTP.post('/affNetwork/pixel/new', body)
}

const affNetworkUpdatePixel = async (body) => {
  return await HTTP.post('/affNetwork/pixel/update', body)
}

const affNetworkDeletePixel = async (body) => {
  return await HTTP.post('/affNetwork/pixel/delete', body)
}

const affNetworkUpdateImprint = async (body) => {
  return await HTTP.post('/affNetwork/update/imprint', body)
}

const affNetworkUpdateKeywords = async (body) => {
  return await HTTP.post('/affNetwork/update/keywords', body)
}

const affNetworkCreateNewCampaign = async (body) => {
  return await HTTP.post('/affNetwork/create/campaign', body)
}

const affNetworkGetCampaignsStatistics = async (body) => {
  return await HTTP.post('/affNetwork/campaigns/statistics', body)
}

const affNetworkGetCampaignInfo = async (body) => {
  return await HTTP.post('/affNetwork/campaign/info', body)
}

const affNetworkGetCampaignDefaultDomain = async (body) => {
  return await HTTP.post('/affNetwork/campaign/default-domain', body)
}

const affNetworkGetCampaignWizard = async (body) => {
  return await HTTP.post('/affNetwork/campaign/wizard', body)
}

const affNetworkGetVerifyDomainAvailability = async (body) => {
  return await HTTP.post('/affNetwork/campaign/wizard/verify-domain-availability', body)
}

const affNetworkGetDomainLookup = async (body) => {
  return await HTTP.post('/affNetwork/campaign/wizard/domain-lookup', body)
}


const getSourceCampaigns = async (body) => {
  return await HTTP.post('/source/campaigns', body)
}

const getSourceCampaignTeasers = async (body) => {
  return await HTTP.post('/source/campaign/teasers', body)
}

const getSourceCatalog = async (networkName) => {
  return await HTTP.get('/source/catalog', {params: {networkName}})
}

const sourceCopyTeaser = async (data) => {
  return await HTTP.post('/source/teasers/copy', data)
}



export default {
  login,
  logout,
  getProfile,
  getOptimizationCatalog,
  getCatalogDsp,
  getCatalogForTeasers,
  getCatalogForEarnings,
  getOptimizationCampaigns,
  getCampaignInfoFromCab,
  getOptimizationStatistic,
  checkCampaign,
  saveNewCampaign,
  cancelModerationCampaign,
  moderationCampaign,
  optimizeCampaign,
  updateValue,
  getHistoryOfFieldForCampaign,
  setLocale,
  linkTelegramAccount,
  getSpendingCatalog,
  getSpending,
  getErrorInUrl,
  optimizationUpdateCampaignLimit,
  optimizationUpdateCampaignStatus,
  // replaceErrorUrl,
  dspStatistic,
  dspTeasers,
  dspTeaserNew,
  dspTeaserDeleteFromModeration,
  dspTeaserDeleteTask,
  dspTeaserSendModerateAgain,
  dspTeaserCancelModerate,
  dspTeaserModerate,
  dspTeaserTasks,
  dspTeaserCreateTask,
  dspTeaserAbTasks,
  dspAuctionStat,
  dspGetClimitAchieved,
  dspTeaserCreateAbTask,
  dspTeaserMassChanges,
  dspTeaserUpdateFromCallback,
  dspTeaserImages,
  dspTeaserImagesTags,
  dspTeaserImagesTagsWithRating,
  dspTeaserExternalUpdates,
  dspTeaserExternalUpdatesListTeasersForTasks,
  dspUpdateImgTags,
  dspUploadImg,
  dspDownloadImg,
  dspTargetUa,
  dspTargetUaInsert,
  dspTargetUaDelete,
  dspTargetUaUpdate,
  dspCatalogTargetUa,
  dspScheduleUpdate,
  dspUpdateLang,
  dspTeasersSetFolder,
  dspTeaserValidate,
  dspUploadFileForParse,
  getCampaignsDayStatistic,
  runFetchCampaignsDayStatistic,
  uploadNetworkStatisticData,
  getEarningStatistic,
  flowList,
  flowCatalog,
  flowCreate,
  tasksList,
  affNetworkGetAccountCatalog,
  affNetworkGetCatalogNetwork,
  affNetworkGetOffers,
  affNetworkGetCampaigns,
  affNetworkGetCampaignsStatistics,
  affNetworkGetCampaignInfo,
  affNetworkUpdateName,
  affNetworkUpdateImprint,
  affNetworkUpdateKeywords,
  affNetworkCreateNewCampaign,
  affNetworkCreatePixel,
  affNetworkUpdatePixel,
  affNetworkDeletePixel,
  affNetworkGetCampaignWizard,
  affNetworkGetVerifyDomainAvailability,
  affNetworkGetDomainLookup,
  affNetworkGetCampaignDefaultDomain,
  getSourceCampaigns,
  getSourceCampaignTeasers,
  getSourceCatalog,
  sourceCopyTeaser,
  dspMediaDescription,
  dspLandingKeywords
}